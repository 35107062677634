import React from 'react';

import {
  Card,
  CardCloseButton,
  CardOptions,
  DetailList,
  DetailListItem,
} from '@/components';
import { useTranslate } from '@/contexts';
import { Nullable } from '@/types';
import formatMoney from '@/utils/formatter/formatMoney';
import {
  KpiBox_node_Viewer_allTimeStats_rows,
  KpiBox_node_Viewer_stats_rows,
} from './__generated__/KpiBox';
import { SanityKpiBlockFragment } from './__generated__/SanityKpiBlockFragment';
import { getValue, metricLabelMap } from './helpers';

type Props = {
  block: SanityKpiBlockFragment;
  metrics: string[];
  row: Nullable<KpiBox_node_Viewer_stats_rows>;
  allTimeRow: Nullable<KpiBox_node_Viewer_allTimeStats_rows>;
  currency: Nullable<string>;
};

const KpiDetails = ({ metrics, block, allTimeRow, row, currency }: Props) => {
  const { t } = useTranslate();

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <DetailList
          items={metrics.flatMap<DetailListItem>((metric) => {
            const value = getValue(metric, row);
            const allTimeValue = getValue(metric, allTimeRow);

            const metricLabel = metricLabelMap[metric] || metric;

            return [
              {
                label: `${metricLabel} - period`,
                value: formatMoney(value ?? 0, currency),
              },
              {
                label: `${metricLabel} - all time`,
                value: formatMoney(allTimeValue ?? 0, currency),
              },
            ];
          })}
        />
      </div>
    </Card>
  );
};

export default KpiDetails;
